import {
  format as dateFnsFormat,
  formatISO9075,
  isSameDay as dateFnsIsSameDay,
  isSameYear as dateFnsIsSameYear,
} from 'date-fns';
import currentDate from '../currentDate';

export { default as currentDate } from '../currentDate';

export function isSameDay(date1, date2) {
  return dateFnsIsSameDay(date1, date2);
}

export function isSameYear(date1, date2) {
  return dateFnsIsSameYear(date1, date2);
}

export function formatDay(date) {
  return date && dateFnsFormat(date, 'yyyy-MM-dd');
}

export function formatDate(date, templateString) {
  return date && dateFnsFormat(date, templateString);
}

export function formatDateTime(date) {
  return date && formatISO9075(date);
}

export function asDay(date) {
  const dateStr = dateFnsFormat(new Date(date), 'yyyy-MM-dd');
  return new Date(dateStr);
}

export function currentDay() {
  return asDay(currentDate());
}
