<template>
  <a-btn-link
    class="PdfDownload icon-left align-right"
    icon="svguse:/svg-icons.svg#icon-download"
    size="sm"
    @click="downloadPdf()"
    :loading="loadingDownload"
    ><slot></slot>
  </a-btn-link>
</template>
<script lang="ts">
import axios from 'axios';
import { InstrumentCategory, Entity, Instrument } from '@shared/web/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatNationalId } from '@shared/common';
import { formatDate, pickDefined } from '@shared/utils';
import { DownloadPDFService } from '@shared/utils/pdf';
import { Products } from '@shared/index';

enum DocumentType {
  legal = 'legal',
  complete = 'complete',
}

@Component
export default class PdfDownload extends Vue {
  @Prop() private readonly documentType: DocumentType;
  @Prop() private readonly settleDate: Date;
  @Prop() private readonly owner: Entity;
  @Prop() private readonly custodian: Entity;
  @Prop() private readonly instrument: Instrument;
  @Prop() private readonly entity: Entity;
  @Prop() private readonly instrumentProduct: Products;
  @Prop({ default: InstrumentCategory.SHA }) private readonly category: InstrumentCategory;

  loadingDownload = false;

  private get url(): string {
    const domainPdf = import.meta.env.VITE_APP_DOMAIN_PDF;
    const params = new URLSearchParams(
      pickDefined({
        type: this.documentType,
        category: this.category ? this.category : undefined,
        owner: this.owner?.id,
        settleDate: this.settleDate?.toISOString(),
        instrumentProduct: this.instrumentProduct,
        instrument: this.instrument?.id,
        custodian: this.custodian?.id,
      }),
    ).toString();
    return `${domainPdf}${this.entity.id}?${params}`;
  }

  private get filename(): string {
    const nationalId = formatNationalId(this.entity, false);
    const formattedDate = formatDate(new Date(), 'yyyyMMdd');
    return `${nationalId} ${formattedDate}.pdf`;
  }

  async downloadPdf(): Promise<void> {
    this.loadingDownload = true;
    try {
      await new DownloadPDFService(axios).download(this.url, { filename: this.filename });
    } catch (error) {
      console.error('Error downloading PDF:', error);
      throw error;
    } finally {
      this.loadingDownload = false;
    }
  }
}
</script>
<style lang="scss">
.PdfDownload {
  position: relative;
  padding-left: 0;

  .q-icon {
    margin-right: 6px;
  }
}
</style>
